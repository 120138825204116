import React from 'react'

export default function Contactus() {
    return (
        <div className="mt-5" style={{ backgroundColor: "#322c2a6c" }}> 
        <div className='container' id="contact">
            <p className='fw-bold text-center fs-2 head_clr py-4'>Contact Us</p>
            <div className="row gap-2 align-items-center ">
                <div className="col-3   text-center  ">
                    <div className="" data-aos="fade-up" data-aos-duration="1600">
                        <p className='fs-5'> <b>Email</b></p>
                        <p className='fs-6'><a href="mailto:info@fourwaylogistics.in" target="_blank" rel="noopener noreferrer">info@fourwaylogistics.in</a></p>
                    </div>
                </div>
                <div className="col-5 text-center   ">
                    <div className="" data-aos="fade-up" data-aos-duration="1600">
                        <p className='fs-5 fw-bold'> Address</p>
                        <p className='fs-6'><b>M/S FOURWAY LOGISTICS (P) LTD.,</b></p>
                        <p className='fs-6'>1st Floor, No 01, Flat no. 14, 1st Floor, MGR Veeranam Road, Near Gobal Info city,Kandanchavadi, Chennai-600 096. </p>


                    </div>
                </div>

                <div className="col-3 text-center" data-aos="fade-up" data-aos-duration="1600">
                    <p className='fs-5'> <b>Quick Support</b></p>
                    <p className='fs-6'><a href=" https://wa.me/044-47677579" target="_blank" rel="noopener noreferrer">044-47677579</a></p>

                </div>


            </div>
          

        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.0309556375814!2d80.245184!3d12.969871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU4JzExLjUiTiA4MMKwMTQnNDIuNyJF!5e0!3m2!1sen!2sin!4v1727884880547!5m2!1sen!2sin"  width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>          

        
        </div>
    )
}
