import React, { useEffect } from 'react'
import { Card, Button, CardBody } from 'react-bootstrap';
import plan1 from "../Asserts/plan1.jfif"
import plan2 from "../Asserts/control2.png"
import local from "../Asserts/local_n2.jpg"
import corp from "../Asserts/corp.jpg"

import bus from "../Asserts/bus.jpg"
import lux from "../Asserts/luxary.png"
import commit from "../Asserts/trip_img.png";
import { IoStar } from "react-icons/io5";
import Carousel from 'react-bootstrap/Carousel';

import ga25 from '../Asserts/ga25.jpeg';
import ga26 from '../Asserts/ga26.jpeg';
import ga3 from '../Asserts/ga3.jpeg';
import gb1 from '../Asserts/gb1.1.jpeg';
import gb2 from '../Asserts/gb1.2.jpeg';
import gb4 from '../Asserts/gb4.jpeg';
import gb5 from '../Asserts/gb5.jpeg';
import gb6 from '../Asserts/gb6.jpeg';
import gb7 from '../Asserts/gb7.jpeg';
import gb8 from '../Asserts/gb8.jpeg';
import gb9 from '../Asserts/gb9.jpeg';




function OurService() {

    return (
        <>

            <div className="container " id="services" >
                <p className='fw-bold text-center fs-2 head_clr py-4'>Our Services</p>

                <div className="d-flex justify-content-center align-items-center gap-4  ">
                    <Card className='cardH rounded' data-aos="fade-up" data-aos-duration="1000" style={{ width: '19rem' }}>
                        <Card.Img className='img-fluid   ' variant="top" src={local} />
                        <Card.Body>
                            <Card.Title className='fw-bold'>LOCAL RENTAL</Card.Title>

                        </Card.Body>
                    </Card>

                    <Card className='cardH rounded   ' data-aos="fade-up" data-aos-duration="1500" style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={corp} />
                        <Card.Body>
                            <Card.Title className='fw-bold'>CORPORATE RENTAL</Card.Title>

                        </Card.Body>
                    </Card>

                    <Card className='cardH rounded  ' data-aos="fade-up" data-aos-duration="2500" style={{ width: '20rem' }}>
                        <Card.Img variant="top" src={lux} />
                        <Card.Body>
                            <Card.Title className='fw-bold'>LUXARY CAR RENTAL</Card.Title>

                        </Card.Body>
                    </Card>
                    <Card className='cardH rounded  ' data-aos="fade-up" data-aos-duration="1000" style={{ width: '20rem' }}>
                        <Card.Img variant="top" src={bus} />
                        <Card.Body>
                            <Card.Title className='fw-bold'>BUS RENTAL</Card.Title>

                        </Card.Body>
                    </Card>
                </div>

                <div className="row">
                    <p className='fw-bold text-center fs-2 head_clr pt-4 mt-3'>Operation </p>

                    <div className="row mb-5 ">
                        <div className="col-3 mt-3">


                            <a class="card1" href="#" data-aos="fade-right" data-aos-duration="1000">
                                <p class="small fs-5">Oneway Pickup.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#" data-aos="fade-right" data-aos-duration="1400">
                                <p class="small fs-5">Pickup and Drop.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#"  data-aos="fade-left" data-aos-duration="1000" >
                                <p class="small fs-5">Monthly Car Rental.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                                                        
                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#"  data-aos="fade-left" data-aos-duration="1400" >
                                <p class="small fs-5">Package Basis.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                                  

                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#" data-aos="fade-right" data-aos-duration="1000"  >
                                <p class="small fs-5">Daily Basis.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                                  

                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#" data-aos="fade-right" data-aos-duration="1400"   >
                                <p class="small fs-5">Flat KM Basis.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                                
                        </div>
                        <div className="col-3 mt-3">
                            
                            <a class="card1" href="#" data-aos="fade-left" data-aos-duration="1400"   >
                                <p class="small fs-5">SLAB Basis.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div className="col-3 mt-3">
                            <a class="card1" href="#"  data-aos="fade-left" data-aos-duration="1000"   >
                                <p class="small fs-5">Head Count Basis.</p>
                                <div class="go-corner" href="#">
                                    <div class="go-arrow ">
                                        <IoStar />
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>

                <div className="conatiner">
                    <p className='fs-2 fw-bold text-center' >Commitment on Service:</p>
                    <div className="d-flex justify-content-around align-items-center gap-3  ">
                        <div className="col-6">
                            <img className='img-fluid ' data-aos="fade-right" data-aos-duration="1100" src={commit} alt="" srcset="" />
                        </div>

                        <div className="col-6 p-3 ">
                            <div className="p-3" data-aos="fade-left" data-aos-duration="1400">

                                <ul className='fw-semibold justify-content-center align-items-between'>

                                    <li>Fourway Logistics (P) Ltd will ensure good quality of vehicles deployed for Customers.
                                    </li>
                                    <br />
                                    <li>
                                        Driver will be deployed as per the mandatory norms of Tamilnadu RTO regulations.
                                    </li>
                                    <br />
                                    <li> FLL will conduct the Induction program for all its new drivers to brief them about CUSTOMERS safety/security policies, Basic duty discipline and Road safety rules.
                                    </li>
                                    <br />
                                    <li>Coordinator will be deployed to manage day-to-day activities based on the business and client requirement.
                                    </li><br />
                                    <li>Back up/ Alternative vehicles will be organized with arrange breakdown.
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="row justify-content-center align-items-center shadow">

                    <div className="col-6 p-3 ">
                        <div className="p-3" data-aos="fade-right" data-aos-duration="1400">
                            <p className='fs-2 fw-bold'>    Operations / Control</p>
                            <ul className='fw-semibold justify-content-center align-items-between'>
                                <li>
                                    Vehicles will report at starting point - 15 Minutes in advance based on the routes planned by the routers.
                                </li><br />
                                <li>Driver reports over Phone on reaching first pick-up point.
                                </li><br />
                                <li> Each vehicle to have Logbook & Attendance sheet to capture Kms and Employees traveled.
                                </li><br />
                                <li> We ensures that, the driver would be in Uniform.
                                </li><br />
                                <li>We ensures that, the drivers will carry mobile phones with hands free for communication.
                                </li><br />
                                <li> FL ensures that, the vehicle would display Company Logo.
                                </li><br />
                                <li> FL ensure that, the implant supervisors with mobile connectivity would be responsible for making the route & other related activities.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6" >
                        <img className='img-fluids w-100' src={plan2} alt="" srcset="" data-aos="fade-left" data-aos-duration="1400" />
                    </div>
                </div>

                <div className="conatiner  ">

                    <p className='fs-2 fw-bold text-center mt-5 mb-0'>Business Roll Out Plan:</p>

                    <div className="row mt-3 justify-content-center align-items-center ">
                        <div className="col-5">
                            <img className='img-fluid' style={{ height: "400px" }} src={plan1} alt="" srcset="" data-aos="fade-right" data-aos-duration="1200" />
                        </div>
                        <div className="col-7 p-3 ">
                            <div className="" data-aos="fade-left" data-aos-duration="1400">



                                <ul className='serli'>
                                    <li> Fourway Logistics Pvt Ltd At any point of given time we are ready to start-up the operations with limited vehicles for emergency situations and BCP situations.We may need 7 working days to start a regular operations subject to official confirmation in written or in LOI.

                                    </li>
                                    <li> Running with 50% of own vehicles,Ready to deploy more than 50% of own vehicles on demand,GPS Tracking system available,Police Verification Certificate (BGV) for Drivers.
                                    </li>

                                    <li> Vehicle deployment Planning,Fleet Optimization,Dedicated coordinator at site,Customized and accurate MIS preparation & Billing.Commitment towards Safety and Security of Customers.
                                    </li>
                                    <li>  Adequate and regular training to drivers on Road safety practices.First Aid training to drivers and coordinators.* 24/7 Services.Support for ad-hoc transport requirement in all segments (Cab, TT, and Bus).

                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container">
                    <div className="row align-items-center mt-5 pt-3">
                        <div className="col-12 col-lg-6">
                            <Carousel autoplay={true} interval={1500} pause={false}>


                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={ga3} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={ga25} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={ga26} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb1} alt="" srcset="" />

                                </Carousel.Item>

                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb4} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb5} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb6} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb7} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb8} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb9} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={gb2} alt="" srcset="" />

                                </Carousel.Item>

                            </Carousel>
                        </div>
                        <div className="col-12 col-lg-6 list ps-4">
                            <p className='fs-2 fw-bold' style={{ color: "#2a1572" }}>Business Continuity Plan:</p>
                            <li> We have a successful management many BCP floods 2016,2017,2023 & Pandamenic etc...,</li>
                            <li> As a result we have got New Clients and Business increase from existing clients</li>
                            <li> During Natural Calamities,we have a strong ground team to handle these situation</li>
                            <li> Deploying Bigger vehicles and also we have supported with small Boat to rescue from water logged areas.</li>
                            <li> Our Team has got Rewards and Recognization by the clients for the support during BCP.</li>

                        </div>

                    </div>
                </div>



            </div>
        </>
    )
}

export default OurService