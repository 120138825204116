import React from 'react'
import CountUp from "react-countup"

export default function Counter({ number, title }) {
  return (
    <div className="number">
    <CountUp duration={10} className="counter fw-bold text-center fs-2 " end={number} />
    <span>{title}</span>
  </div>
  )
}
