import React from 'react';
import Visson from "../Asserts/visson.png"
import Mission from "../Asserts/Mission.png"
import oneway from "../Asserts/oneway.png"
import { TbBackground } from 'react-icons/tb';

import compliance from "../Asserts/driver_audit.png"

import { IoStar } from "react-icons/io5";
import Carousel from 'react-bootstrap/Carousel';

import t1 from "../Asserts/training1.jpeg"
import t2 from "../Asserts/training2.jpeg"
import t3 from "../Asserts/training3.jpeg"
import t4 from "../Asserts/training4.jpeg"
import t5 from "../Asserts/training5.jpeg"
import t6 from "../Asserts/training6.jpeg"
import t7 from "../Asserts/training7.jpeg"
import t8 from "../Asserts/training8.jpeg"
import t9 from "../Asserts/training9.jpeg"
import t10 from "../Asserts/training_safety.jpeg"



function Compliance() {

    return (
        <>
            <div className=" mt-3 " id="compliance">
                <div className=" pb-3">
                    <p className='fw-bold text-center fs-2 head_clr py-4'>Compliance/Traning</p>

                    <div className="container">
                        <div className="row py-5 bg-info bg-opacity-50 justify-content-center align-items-center shadow">
                            <div className="col-6">
                                <img className='img-fluid' src={compliance} alt="" srcset="" data-aos="fade-right" data-aos-duration="1600" />
                            </div>
                            <div className="col-6 p-3 " >
                                <div className="p-3" data-aos="fade-left" data-aos-duration="1200">

                                    <p className='fw-semibold '>
                                        **	We are have dedicted Trainer for driver Grooming and Training
                                        <br />
                                        <br />
                                        ** Following certificate training conducted.
                                        <br /><br />
                                        **	Defensive.
                                        <br /><br />
                                        **	Behavioural.
                                        <br /><br />
                                        **	First Aid and Emergency Training.
                                        <br /><br />
                                        **	Authorised Vehicle Maintenance Training.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row align-items-center mt-5 pt-3">
                        <div className="col-12 col-lg-6">
                            <Carousel autoplay={true} interval={1500} pause={false}>


                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t1} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t2} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t3} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t4} alt="" srcset="" />

                                </Carousel.Item>

                            
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t6} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t7} alt="" srcset="" />

                                </Carousel.Item>
                               
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t9} alt="" srcset="" />

                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <ExampleCarouselImage text="First slide" /> */}
                                    <img className='img-fluid text-center w-100' style={{ width: "60%", height: "400px" }} src={t10} alt="" srcset="" />

                                </Carousel.Item>

                            </Carousel>
                        </div>
                        <div className="col-12 col-lg-6 list ps-4">
                            <p className='fs-2 fw-bold' style={{ color: "#2a1572" }}>Training and Drivers on the following points:</p>
                            <li> Defensive Driving</li>
                            <li> Traffic Rules</li>
                            <li> Vehicle Maintenance</li>
                            <li> Traffic Signaling</li>
                            <li> Roadside Assistance</li>
                            <li> Driver’s Education</li>
                            <li>Vehicle Documentation</li>
                            <li>Required Vehicle Accessories.</li>
                            <li>  Vehicle Inspection and Repair</li>
                            <li> Focus on Speed, Seat Belt and Emergency handling.</li>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Compliance