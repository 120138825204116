import React from 'react';

import Visson from "../Asserts/visson.png"
import Mission from "../Asserts/Mission.png"
import oneway from "../Asserts/oneway.png"
import { TbBackground } from 'react-icons/tb';

import Iroad from "../Asserts/iroad.png"
import Ivecle from "../Asserts/icar.png"
import Iclient from "../Asserts/iclient.png"

import car1 from "../Asserts/btm-car2.webp"
import Counter from './Counter';


function AboutUs() {
    return (
        <>
            <div className="container mt-3" id="about">

                <div className="numbers">
                    <div className="count_cont " data-aos="fade-right" data-aos-duration="1400">  <img className='img-fluid' src={Iroad} alt="" srcset="" /> <p> <Counter className="counter fw-bold text-center fs-2 text-white" number={1750} title="" /> <span>Daily Trip</span></p></div>
                    <div className="count_cont" data-aos="zoom-in" data-aos-duration="1400">  <img className='img-fluid' src={Ivecle} alt="" srcset="" /> <p> <Counter number={750} title="" className="text-primary" /> <span>Number of Vehicles</span></p></div>
                    {/* <div className="count_cont" data-aos="fade-left" data-aos-duration="1400">  <img className='img-fluid' src={Iclient} alt="" srcset="" /> <p><Counter number={468} title="" /> <span>Number of Clients</span></p></div> */}
                </div>
                <p className='fw-bold text-center fs-2 head_clr py-4'>About Us</p>

                <div className="row justify-content-center align-items-center">
                   
                    <div className="col-12 p-3" >
                        <div className="p-3" >
                            
                        <p className='fw-semibold ' data-aos="fade-left" data-aos-duration="900">
                        We are the fastest growing Transport service providers in the industry. With a well experience and expertise ground staff to provide comfy and Cost effective Logistics.                                <br />
                              </p>

                            <p className='fw-semibold ' data-aos="fade-left" data-aos-duration="900">
                                <b> Fourway Logistics (P) Ltd</b>. is an innovative SME company started by <b> Mr. PARTHIBAN& Mr. SARAVANAN</b> who has 15 years of rich hands on experience in managing staff transportation services of various Indian and MNCs- IT, ITES, BPO, KPO, TELECOM, MANUFACTURING and BANKING companies in Tamilnadu.
                                <br />
                              </p>
                            <p className='fw-semibold ' data-aos="fade-left" data-aos-duration="1100">   The name Fourway is to mean the 4 key ways of transportations Road, Rail, Air and Sea. Our Aim is to entering into all this four ways of transportations andto be a leader in the market by strategic partnering with our esteemed Clients.
                                <br /></p>
                            <p className='fw-semibold ' data-aos="fade-left" data-aos-duration="1200" >  Initially we started our service in FMS (Fleet Management Service) i.e. Staff transportation with the hard work and a wide range of experience, we became very strong in FMS. By seeing our committed quality & timely service in FMS out existing clients referring us for other clients as well.
                            </p>
                        </div>
                    </div>
                </div>
                 

                <div className="row align-items-center mt-2">
                    <div className="col-12 col-lg-6">
                        <p className='fs-2 fw-bold' data-aos="fade-right" data-aos-duration="900">Our Mission</p>
                        <p data-aos="fade-right" data-aos-duration="1100"> Our Mission is to Serve our Client by Providing Comfy, Cost Effective, Technology driven Logistics Service that continually meet the Client Expections.

</p>                    </div>
                    <div className="col-12 col-lg-6">
                        <img data-aos="fade-left" data-aos-duration="900" className='img-fluid' src={Mission} alt="" srcset="" />
                    </div>
                </div>

                <div className="row align-items-center mt-2">
                    <div className="col-12 col-lg-6">
                        <img className='img-fluid' data-aos="fade-right" data-aos-duration="1000" src={Visson} alt="" srcset="" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <p className='fs-2 fw-bold' data-aos="fade-left" data-aos-duration="1200">Our Vision</p>
                        <p data-aos="fade-left" data-aos-duration="1100">
							To be one of the most preferred logistics company in the industry by being a key partner to all our customers and their success.
							We are committed to our relationship with our Customers, Vendors, Associates and be a part of their success with integrity and responsibility.
                        </p>                    </div>

                </div>

              

            </div>
            <section class="cars-sec py-md-5">
                <div class="container-fluid d-none">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-1">
                                <div class="car-top-content txt-white">
                                    <h4 class="animation-element slide-top">Hatchback</h4>
                                    <p>Stylish efficiency for urban explorers.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src={car1} alt="hatchback car" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-2">
                                <div class="car-top-content ">
                                    <h4 class="animation-element slide-top">Sedan</h4>
                                    <p>Experience luxury, journey in comfort.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src="images/btm-car1.webp" alt="sedan car" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-3">
                                <div class="car-top-content txt-white">
                                    <h4 class="animation-element slide-top">Luxury Sedan</h4>
                                    <p>Redefine your travel experience.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src="images/btm-car3.webp" alt="luxury sedan car" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-4">
                                <div class="car-top-content txt-white">
                                    <h4 class="animation-element slide-top">SUV/MUV</h4>
                                    <p>Adventure-ready, spacious, power-packed.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src="images/btm-car4.webp" alt="SUV/MUV car" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-5">
                                <div class="car-top-content ">
                                    <h4 class="animation-element slide-top">Traveller</h4>
                                    <p>Group travel made comfy, seamless.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src="images/btm-car5.webp" alt="traveller" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-car-bg bgclr-6">
                                <div class="car-top-content txt-white">
                                    <h4 class="animation-element slide-top">Luxury Coach</h4>
                                    <p>Luxury on wheels, convenience elevated.</p>
                                </div>
                                <div class="car-btm-img">
                                    <img src="images/btm-car6.webp" alt="luxury coach" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs