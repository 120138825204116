import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from "../Asserts/ga13N.JPG"
import Banner3 from "../Asserts/ga20N.JPG"
import Banner6 from "../Asserts/banner_6.png"
import Banner8 from "../Asserts/banner_8.jpg"

function Banner() {
    return (
        <>
        <div className='' id="home">
            <Carousel autoplay={true} interval={2000} pause={false}>
                <Carousel.Item>
                  
                    <img className='img-fluid text-center w-100' style={{ width: "80%",height:"500px"  }} src={Banner1} alt="" srcset="" />

                </Carousel.Item>
               
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img className='img-fluid text-center w-100' style={{ height:"500px" }} src={Banner6} alt="" srcset="" />

                </Carousel.Item>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img className='img-fluid text-center w-100' style={{ width: "80%",height:"500px" }} src={Banner3} alt="" srcset="" />

                </Carousel.Item>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img className='img-fluid text-center w-100' style={{ width: "80%",height:"500px" }} src={Banner8} alt="" srcset="" />

                </Carousel.Item>

            </Carousel>
            </div>
        </>
    )
}

export default Banner