import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import C1 from "../Asserts/C1.jpeg"
import C2 from "../Asserts/C2.jpeg"
import C3 from "../Asserts/C3.jpeg"
import C4 from "../Asserts/C4.jpeg"
import C5 from "../Asserts/C5.jpeg"
import C6 from "../Asserts/C6.jpeg"
import C7 from "../Asserts/C7.jpeg"
import C8 from "../Asserts/C8.jpeg"
import C9 from "../Asserts/C9.jpeg"
import C10 from "../Asserts/C10.jpeg"
import C11 from "../Asserts/C11.jpeg"

 

function OurClients() {

 
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

 
    return (
        <>
            <p className='fw-bold text-center fs-2 head_clr py-4' id="clients">Our Clients </p>

            <div className="pt-0 mb-3" style={{ marginTop: "-15px" }}>

                <Carousel responsive={responsive} customTransition="all 6s linear" infinite={true} pauseOnHover={false} arrows={false} swipeable={false} autoPlay={true} centerMode={true}>
                    <div><img className='img-fluid' src={C1} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C2} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C3} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C4} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C5} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C6} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C7} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C8} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C9} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C10} alt="" srcset="" /></div>
                    <div><img className='img-fluid' src={C11} alt="" srcset="" /></div>
                </Carousel>
            </div>

         
        </>
    )
}

export default OurClients