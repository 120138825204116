

import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/App.css"
function App() {
  return (
    <>
      
      <Home />
      
    </>
  );
}

export default App;