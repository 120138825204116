import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../Asserts/fourway_logo.png";

function NavBar() {
    return (
        <>
          <div className="" style={{ backgroundColor: "#2a1571",fontSize:"14px" }}>
          <div className="container top_bar" >
           <div className="my-auto pt-2 text-white d-flex justify-content-around align-items-center gap-4" >
             <a href="tel: 044-47677579" target="_blank" rel="noopener noreferrer">  <p className='d-flex align-items-center gap-2 '><i class="fa fa-mobile-phone" style={{fontSize:"30px"}}></i> 044-47677579</p></a>
               {/* <p className='d-flex align-items-center gap-2'><i class='material-icons' style={{fontSize:"30px"}}>person</i> Parthiban R</p>
                */}
               <p className='d-flex align-items-center gap-2'> <i class='fa fa-clock-o' style={{fontSize:"25px"}}></i> 24/7 Services</p>
             <a href="mailto:info@fourwaylogistics.in" target="_blank" rel="noopener noreferrer">  <p className='d-flex align-items-center gap-2'><i class='material-icons' style={{fontSize:"25px"}}>email</i> info@fourwaylogistics.in</p></a>
             <a href="tel: 044-47677579" target="_blank" rel="noopener noreferrer">  <p className='d-flex align-items-center gap-2'> <i class="fa fa-car" style={{fontSize:"25px"}}></i> Attach </p></a>
            </div>
           </div>
          </div>
            <Navbar bg="" className='navbar sticky-top shadow' data-bs-theme="dark" >
                <Container >
                    {<Navbar.Brand href="#home"><img className='img-fluid shdaow' style={{ width: "80%" }} src={logo} alt="" srcset="" /></Navbar.Brand>}
                    <Nav className="me-auto gap-4 position-relative">
                        <Nav.Link className='navlink' href="#home">Home</Nav.Link>
                        <Nav.Link className='navlink' href="#about">About Us</Nav.Link>
                        <Nav.Link className='navlink' href="#services">Services</Nav.Link>
                        <Nav.Link className='navlink' href="#clients">Clients </Nav.Link>
                       
                        <Nav.Link className='navlink' href="#compliance">Compliance </Nav.Link>
                        <Nav.Link className='navlink' href="/#gallery">Gallery </Nav.Link>
                        <Nav.Link className='navlink' href="#contact">Contact Us </Nav.Link>
                    </Nav>
                </Container>
            </Navbar></>
    )
}

export default NavBar