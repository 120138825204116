import React from 'react';
import logo from "../Asserts/logo_footer.png"

function Footer() {
  return (
    <>
    <div className="foter_cont  rounded-3 pb-3">
        <div className="container">
            <div className="row   justify-content-center ">
               
                <div className="col-3 my-auto text-white  pt-1">
                    <p className='fs-3 fw-semibold'>Useful Links</p>
                    <p className='fs-6'>Home</p>
                    <p className='fs-6'>About Us</p>
                    <p className='fs-6'>Services</p> 
                    
                     </div>
                 <div className="col-3 my-auto text-white  pt-1">
				 <p></p>
                   <p className='fs-6'>Clients</p> 
                    <p className='fs-6'>Compilance</p> 
                    <p className='fs-6'>Gallery</p> 
                    <p className='fs-6'>Contact Us</p> 
                    
                    
                     </div>
                <div className="col-4 text-white pt-3">
                    
                    <p className='fs-3 fw-semibold'>Contact Us</p>
                    <p className='fs-6'>M/S FOURWAY LOGISTICS (P) LTD.,</p>
                    <p className='fs-6'>1st Floor, No 01, Flat no. 14, 1st Floor, MGR Veeranam Road, Near Gobal Info city, </p>
                    <p className='fs-6'>Kandanchavadi, Chennai-600 096.</p>
                    <p className='fs-6'>info@fourwaylogistics.in</p>

                     </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer