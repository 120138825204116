import React, { useEffect } from 'react';
import NavBar from '../Component/NavBar';
import Banner from '../Component/Banner';
import OurService from '../Component/OurService';
import AboutUs from '../Component/AboutUs';
import Galleryy from '../Component/Gallery';
import OurClients from '../Component/OurClients';
import Footer from '../Component/Footer';
import Compliance from '../Component/Compliance';
import Contactus from '../Component/Contactus';
import Counter from '../Component/Counter';
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  // useEffect(() => {
    AOS.init();
  // }, []);
  return (
    <>
      <NavBar />
      <Banner />

      <AboutUs />
      <OurService />
      <Galleryy />
      <OurClients />
      <Compliance/>
      <Contactus />
     
      <Footer />
    </>
  )
}

export default Home