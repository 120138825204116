import React, { useState, useEffect } from 'react';
// import gallery from '../Asserts/gallery.jfif';
// import gal1 from '../Asserts/gal1.JPG';
// import gal2 from '../Asserts/gal2.JPG';
// import gal3 from '../Asserts/gal3.jpg';
// import gal4 from '../Asserts/gal4.jp.JPG';
// import gal6 from '../Asserts/gal6.jpg';

// import gal8 from '../Asserts/gal8.png';
// import gal9 from '../Asserts/gal9.png';
// import gal10 from '../Asserts/gal10.png';
import gal1 from '../Asserts/gallery/gal1.jpeg';
import gal2 from '../Asserts/gallery/gal2.jpeg';
import gal3 from '../Asserts/gallery/gal3.jpeg';
import gal4 from '../Asserts/gallery/gal4.jpeg';
import gal5 from '../Asserts/gallery/gal5.jpeg';
import gal6 from '../Asserts/gallery/gal6.jpeg';
import gal7 from '../Asserts/gallery/gal7.jpeg';
import gal8 from '../Asserts/gallery/gal8.jpeg';
import gal9 from '../Asserts/gallery/gal9.jpeg';
import gal11 from '../Asserts/gallery/gal11.jpeg';
import gal12 from '../Asserts/gallery/gal12.jpeg';
import gal13 from '../Asserts/gallery/gal13.jpg';
import gal14 from '../Asserts/gallery/gal14.jpeg';
import gal15 from '../Asserts/gallery/gal15.jpeg';
import gal16 from '../Asserts/gallery/gal16.jpeg';

const allImages = [
   
    gal4,
    gal5,
    gal6,
    gal7,
    gal8,
    gal9,
    gal13,
    gal3,
    gal1,
    gal2,
    gal11,
    gal12,
    gal14,
    gal15,
    gal16,
];

function Gallery() {

    const [visibleImages, setVisibleImages] = useState([]);
    const [visibleCount, setVisibleCount] = useState(6);

    useEffect(() => {
        // Load the initial set of images
        setVisibleImages(allImages.slice(0, visibleCount));
    }, [visibleCount]);

    const loadMoreImages = () => {
        setVisibleCount(prevCount => {
            const newCount = prevCount + 5;
            setVisibleImages(allImages.slice(0, newCount));
            return newCount;
        });
    };
    return (
        <>

            {/* <div className="container mb-5" id="gallery">
                <p className='fw-bold text-center fs-2 head_clr py-5'>Gallery</p>
                <div className="row row-cols-3 row-cols-lg-4 g-4">
                    <img className='img-fluid col-3 imghov' src={gal8} alt="" srcset="" /> 


                </div>

            </div> */}

            <div className="container mb-5" id="gallery">
            <p className='fw-bold text-center fs-2 head_clr py-5'>Gallery</p>
                <div className="image-gallery">
                    {visibleImages.map((image, index) => (
                        <img key={index} src={image} alt={`Gallery ${index}`} className="gallery-image imghov" />
                    ))}
                </div>
                {visibleCount < allImages.length && (
                   <div className="d-flex justify-content-center mt-2" >
                     <button onClick={loadMoreImages} style={{backgroundColor:"#2a1571"}} className="load-more-button  text-white btn btn-primary btn-sm d-flex justify-content-center gap-2">
                      <span>Load More</span> <span><i class="fa fa-spinner" style={{fontSize:"19px"}}></i></span>
                    </button>
                   </div>
                )}
            </div>



        </>
    )
}

export default Gallery